import React, { useMemo } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import {
	getCountryCode,
	hasGroupMembership,
	isFeatureEnabled,
	useFlags,
	useMembershipContext,
	useUserContext
} from 'common';

import MediathekAddons from './UserWelcomeHeader/MediathekAddons';
import { useRecentlyStartedCoursesQuery } from '../../../graphql/catalog/queries/queries.generated';
import MediathekStartedOnDemandCourses from './MediathekStartedOnDemandCourses';
import UserWelcomeHeader from './UserWelcomeHeader/UserWelcomeHeader';
import { useIntl } from 'react-intl';

const useStyles = makeStyles((theme) => ({
	addonsContainer: {
		width: '100%',
		flexBasis: '100%',
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(2),

		[theme.breakpoints.up('md')]: {
			order: 2,
			marginTop: 0,
			marginBottom: theme.spacing(7)
		}
	}
}));

export const MediathekHeader = () => {
	const classes = useStyles();
	const { isLoading: isLoadingUser } = useUserContext();
	const { locale } = useIntl();
	const countryCode = getCountryCode(locale);
	const {
		learningOnDemandAccreditationChTemp14102024: onDemandAccreditationCh,
		learningOnDemandAccreditationAtTemp14102024: onDemandAccreditationAt
	} = useFlags();
	const onDemandAccreditationEnabled = useMemo(() => {
		const onDemandAccreditationLocalFeature = isFeatureEnabled(
			'onDemandAccreditation',
			countryCode
		);
		if (!onDemandAccreditationLocalFeature) {
			return false;
		}
		switch (countryCode) {
			case 'de':
				return true;
			case 'ch':
				return onDemandAccreditationCh;
			case 'at':
				return onDemandAccreditationAt;
			default:
				return false;
		}
	}, [countryCode, onDemandAccreditationCh, onDemandAccreditationAt]);

	const { userMembership, loading: isLoadingMembership } = useMembershipContext();
	const { data: coursesData } = useRecentlyStartedCoursesQuery({
		skip: !onDemandAccreditationEnabled
	});
	const userHasGroupMembership = hasGroupMembership(userMembership);
	const hasStartedSomeOnDemandCourse = Boolean(
		onDemandAccreditationEnabled && coursesData?.recentlyStartedCourses?.length
	);

	if (isLoadingUser || isLoadingMembership) {
		return null;
	}

	if (userHasGroupMembership || hasStartedSomeOnDemandCourse) {
		return (
			<>
				<UserWelcomeHeader />
				{userHasGroupMembership && <MediathekAddons className={classes.addonsContainer} />}
				{hasStartedSomeOnDemandCourse && (
					<MediathekStartedOnDemandCourses className={classes.addonsContainer} />
				)}
			</>
		);
	}

	return <UserWelcomeHeader />;
};
