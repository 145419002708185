import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(
	(theme) => ({
		container: {
			display: 'flex',
			flexWrap: 'wrap',

			order: 0,
			padding: theme.spacing(8, 3, 7, 3),

			[theme.breakpoints.down('md')]: {
				padding: theme.spacing(4)
			},

			[theme.breakpoints.down('sm')]: {
				padding: theme.spacing(4, 4, 2, 4)
			}
		},
		groupContainer: {
			display: 'flex',
			flexWrap: 'wrap',

			order: 0,
			padding: theme.spacing(8, 3, 0, 3),

			[theme.breakpoints.down('md')]: {
				padding: theme.spacing(4)
			},

			[theme.breakpoints.down('sm')]: {
				padding: theme.spacing(3, 3, 0, 3)
			}
		},
		title: {
			textAlign: 'start',
			marginBottom: theme.spacing(1)
		},
		titleContainer: {
			flexBasis: '65%',
			[theme.breakpoints.down('lg')]: {
				flexBasis: '50%'
			},
			[theme.breakpoints.down('md')]: {
				flexBasis: '100%'
			}
		},
		nameTitle: {
			textAlign: 'start',
			color: theme.palette.primary.main,
			fontWeight: 'normal',
			marginBottom: theme.spacing(3)
		},
		subtitle: {
			textAlign: 'start',
			color: theme.palette.primary.main,
			fontWeight: 'normal',
			marginBottom: theme.spacing(3)
		},
		companyContainer: {
			flexBasis: '100%',
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
			marginBottom: theme.spacing(4),

			[theme.breakpoints.up('md')]: {
				flexBasis: '50%',
				order: 1,
				marginBottom: theme.spacing(0)
			},

			[theme.breakpoints.up('lg')]: {
				flexBasis: '35%'
			}
		},
		companyLogo: {
			width: 80,
			height: 80,

			[theme.breakpoints.up('md')]: {
				width: 160,
				height: 160
			}
		},
		companyName: {
			fontWeight: 600,
			textAlign: 'center',
			color: '#59748D'
		},
		membershipContainer: {
			marginTop: theme.spacing(3),
			display: 'flex',
			alignItems: 'center',

			[theme.breakpoints.up('md')]: {
				marginTop: theme.spacing(1)
			}
		},
		membershipIcon: {
			width: 24,
			height: 24,
			marginRight: theme.spacing(1.5),
			color: theme.palette.secondary.main
		},
		membershipTitle: {
			color: theme.palette.primary.main,
			display: 'flex',
			alignItems: 'center'
		},
		addonsContainer: {
			marginBottom: theme.spacing(5),
			[theme.breakpoints.up('sm')]: {
				marginBottom: theme.spacing(9)
			}
		}
	}),
	{
		name: 'GroupMembersHeader'
	}
);

export default useStyles;
