import { useCallback } from 'react';
import {
	Types,
	buildURLWithParams,
	MEDIATHEK_URLS,
	useChangeURL,
	CATALOG_URLS,
	Fragments
} from 'common';
import { contentUtils } from '../utils';
import { catalogTracking } from '../utils/segment';
import { useIntl } from 'react-intl';
import { ContentDocumentFields } from '../utils/content';

/**
 * Generates URL for a video content. If the videos is part of a course, the URL will point to the video in the playlist of that course, otherwise it will point to the video itself.
 */
export const generateContentWithCourseURL = (content: GenerateContentUrlProps) => {
	const contentId = content.contentId;
	let parentId = null;
	if (
		content.contentType === Types.ContentType.Lecture &&
		(content.parent?.contentType === Types.ContentType.Course ||
			content.parent?.contentType === Types.ContentType.Webinar)
	) {
		parentId = content.parent.contentId;
	}
	let newUrl = buildURLWithParams(MEDIATHEK_URLS.content, [parentId ?? contentId]);

	const videoPlayingUrlPath = `/${content.contentId}`;

	if (parentId) {
		newUrl += videoPlayingUrlPath;
	}
	return newUrl;
};

type GenerateContentUrlProps = Pick<
	Types.Content,
	'startDateTime' | 'endDateTime' | 'brand' | 'slug' | 'contentId' | 'contentType'
> & {
	parent?: Pick<NonNullable<Types.Content['parent']>, 'contentId' | 'contentType'> | null;
};

export const generateContentURL = (content: GenerateContentUrlProps) => {
	const isLive = contentUtils.isLive({
		startDateTime: content.startDateTime,
		endDateTime: content.endDateTime
	});

	if (
		(isLive || contentUtils.isInTheFuture(content)) &&
		content.brand !== Types.ProductBrand.Summedup &&
		content.brand !== Types.ProductBrand.Skill
	) {
		const contentUrl = buildURLWithParams(CATALOG_URLS.content, [
			content.slug ?? content.contentId
		]);
		return contentUrl;
	} else {
		return generateContentWithCourseURL(content);
	}
};

type UseOnContentClickProps = (
	| ContentDocumentFields
	| Fragments.ContentDocumentSearchFieldsFragment
	| Fragments.ContentWithProgramSchedulesFieldsFragment
) &
	GenerateContentUrlProps;

const useOnContentClick = () => {
	const changeURL = useChangeURL();
	const { locale } = useIntl();
	const onClickFn = useCallback(
		(content: UseOnContentClickProps, params: { index: number | undefined }) => () => {
			const url = generateContentURL(content);
			changeURL(url, 'catalog', true);
			catalogTracking.contentClicked(content.contentId, { ...params, locale });
		},
		[]
	);
	return onClickFn;
};

export default useOnContentClick;
